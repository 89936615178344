// src/pages/ProductDetails.js
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import ContactModal from "../components/ContactModal";
import tShirt1 from "../assets/images/tshirts/1.jpeg";
import tShirt2 from "../assets/images/tshirts/2.jpeg";
import tShirt3 from "../assets/images/tshirts/3.jpeg";

const productImages = [tShirt1, tShirt2, tShirt3];

const ProductDetails = () => {
  const { id } = useParams();
  const [selectedProduct, setSelectedProduct] = useState({
    id: parseInt(id),
    name: `T-Shirt ${id}`,
    price: `₹${id * 500 + 100}`,
    imageUrl: productImages[id - 1],
  });

  const handleOrderNow = () => {
    // Trigger the modal to open
    const modal = new window.bootstrap.Modal(
      document.getElementById("contactModal")
    );
    modal.show();
  };

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="container mt-5 mb-5">
      <motion.div
        className="row"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.5 }}
      >
        <div className="col-md-6 mb-4">
          <motion.img
            src={selectedProduct.imageUrl}
            alt={selectedProduct.name}
            className="img-fluid rounded shadow-sm"
            style={{ maxHeight: "400px", objectFit: "cover" }}
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
        </div>
        <div className="col-md-6">
          <motion.h1
            className="mb-3"
            variants={fadeIn}
            transition={{ delay: 0.2 }}
          >
            {selectedProduct.name}
          </motion.h1>
          <motion.h3
            className="mb-4"
            variants={fadeIn}
            transition={{ delay: 0.4 }}
          >
            {selectedProduct.price}
          </motion.h3>
          <motion.p
            className="mb-4"
            variants={fadeIn}
            transition={{ delay: 0.6 }}
          >
            Description: This is a detailed description of the{" "}
            {selectedProduct.name}. It highlights the unique features and
            quality of the t-shirt to attract potential buyers.
          </motion.p>
          <motion.button
            className="btn btn-primary btn-lg"
            onClick={handleOrderNow}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            Order Now
          </motion.button>
        </div>
      </motion.div>

      <div className="mt-5">
        {/* Additional Content or Related Products Section */}
      </div>

      {/* Modal should be mounted once, not conditionally */}
      <ContactModal selectedProduct={selectedProduct} />
    </div>
  );
};

export default ProductDetails;
