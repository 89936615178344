import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import tShirt1 from "../assets/images/tshirts/1.jpeg";
import tShirt2 from "../assets/images/tshirts/2.jpeg";
import tShirt3 from "../assets/images/tshirts/3.jpeg";

const HomePage = () => {
  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const slideIn = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const scaleUp = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: { scale: 1, opacity: 1 },
  };

  const rotateIn = {
    hidden: { rotate: -45, opacity: 0 },
    visible: { rotate: 0, opacity: 1 },
  };

  const bounceIn = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  const staggerContainer = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const fadeUp = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  const hoverEffect = {
    hover: { scale: 1.1, rotate: 10 },
  };

  const glowEffect = {
    hover: { boxShadow: "0px 0px 20px rgba(255,255,255,0.8)" },
  };

  const buttonPress = {
    hover: { scale: 1.05 },
    tap: { scale: 0.95 },
  };

  return (
    <div className="home-page">
      {/* Hero Section */}
      <motion.section
        className="hero text-white text-center py-5"
        style={{
          background: `linear-gradient(
              rgba(0, 0, 0, 0.6), 
              rgba(0, 0, 0, 0.6)
            ), url('path_to_your_image') center/cover no-repeat`,
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 1 }}
      >
        <motion.div
          className="container hero-content"
          variants={staggerContainer}
        >
          <motion.h1
            className="display-3 font-weight-bold"
            variants={fadeUp}
            transition={{ duration: 1, delay: 0.2 }}
          >
            Welcome to Seven Clothing
          </motion.h1>
          <motion.p
            className="lead"
            variants={fadeUp}
            transition={{ duration: 1, delay: 0.4 }}
          >
            Elevate your style with our premium t-shirts collection
          </motion.p>
          <motion.div variants={buttonPress} whileHover="hover" whileTap="tap">
            <Link
              to="/products"
              className="btn btn-light btn-lg mt-3 px-5 py-3"
            >
              Shop Now
            </Link>
          </motion.div>
        </motion.div>
      </motion.section>

      {/* Featured Products Section */}
      <motion.section
        className="featured-products py-5 bg-light"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 1, delay: 0.6 }}
      >
        <div className="container">
          <h2 className="text-center mb-5 font-weight-bold">
            Featured T-Shirts
          </h2>
          <motion.div className="row" variants={staggerContainer}>
            {[tShirt1, tShirt2, tShirt3].map((image, index) => (
              <motion.div
                className="col-md-4 mb-4"
                key={index}
                initial="hidden"
                animate="visible"
                variants={bounceIn}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <motion.div
                  className="card h-100 shadow-sm"
                  variants={hoverEffect}
                  whileHover="hover"
                >
                  <motion.img
                    src={image}
                    className="card-img-top"
                    alt={`Featured T-Shirt ${index + 1}`}
                    style={{ objectFit: "cover", height: "300px" }}
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.3 }}
                  />
                  <div className="card-body text-center">
                    <motion.h5
                      className="card-title"
                      variants={fadeUp}
                      transition={{ delay: index * 0.3 }}
                    >
                      T-Shirt {index + 1}
                    </motion.h5>
                    <motion.p
                      className="card-text"
                      variants={fadeUp}
                      transition={{ delay: index * 0.4 }}
                    >
                      ₹{(index + 1) * 500 + 100}
                    </motion.p>
                    <motion.div variants={buttonPress}>
                      <Link
                        to={`/products/${index + 1}`}
                        className="btn btn-primary btn-sm px-4"
                      >
                        View Details
                      </Link>
                    </motion.div>
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.section>

      {/* About Us Section */}
      <motion.section
        className="about-us py-5"
        initial="hidden"
        animate="visible"
        variants={slideIn}
        transition={{ duration: 1, delay: 1 }}
      >
        <div className="container">
          <h2 className="text-center mb-4 font-weight-bold">
            About Seven Clothing
          </h2>
          <motion.div className="row justify-content-center" variants={fadeUp}>
            <motion.div className="col-lg-8">
              <motion.p className="text-center lead">
                At Seven Clothing, we believe that great fashion starts with
                high-quality, stylish t-shirts. Our collection is curated to
                offer something for everyone, from classic whites to trendy
                graphic prints. Our commitment to quality and design ensures
                that you not only look good but feel great. Explore our range
                and discover your new favorite t-shirt today!
              </motion.p>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>

      {/* Call to Action Section */}
      <motion.section
        className="cta bg-secondary text-white text-center py-5"
        initial="hidden"
        animate="visible"
        variants={rotateIn}
        transition={{ duration: 1, delay: 1.5 }}
      >
        <div className="container">
          <h2 className="mb-4">Stay Updated</h2>
          <motion.p variants={fadeUp}>
            Sign up for our newsletter to get the latest updates on new
            arrivals, special offers, and more!
          </motion.p>
          <motion.div variants={glowEffect} whileHover="hover">
            <Link to="/contact" className="btn btn-light btn-lg">
              Contact Us
            </Link>
          </motion.div>
        </div>
      </motion.section>

      {/* Footer Section */}
      <motion.footer
        className="bg-dark text-white text-center py-3"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 1, delay: 2 }}
      >
        <p>&copy; 2024 Seven Clothing. All rights reserved.</p>
      </motion.footer>
    </div>
  );
};

export default HomePage;
