// src/pages/ContactPage.js
import React, { useState } from "react";
import { motion } from "framer-motion";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);
    setStatus("Thank you for reaching out! We will get back to you soon.");
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="contact-page">
      {/* Hero Section */}
      <motion.section
        className="hero bg-primary text-white text-center py-5"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.8 }}
      >
        <div className="container">
          <h1 className="display-4">Contact Us</h1>
          <p className="lead">We’d love to hear from you!</p>
        </div>
      </motion.section>

      {/* Contact Form Section */}
      <motion.section
        className="contact-form py-5"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <div className="container">
          <h2 className="text-center mb-4">Get in Touch</h2>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="subject" className="form-label">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="form-control"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="form-control"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary btn-lg">
              Send Message
            </button>
          </form>
          {status && <div className="alert alert-success mt-3">{status}</div>}
        </div>
      </motion.section>

      {/* Contact Information Section */}
      <motion.section
        className="contact-info bg-light py-5"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <div className="container">
          <h2 className="text-center mb-4">Our Address</h2>
          <p className="text-center">
            Seven Clothing
            <br />
            Jaipur, Rajasthan, 302006
            <br />
            INDIA
          </p>
          <p className="text-center">
            <strong>Email:</strong> support@sevenclothing.in
            <br />
            <strong>Phone:</strong> +91-7014640375
          </p>
        </div>
      </motion.section>
    </div>
  );
};

export default ContactPage;
