// src/pages/AboutPage.js
import React from "react";
import { motion } from "framer-motion";

const AboutPage = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="about-page">
      {/* Hero Section */}
      <motion.section
        className="hero bg-primary text-white text-center py-5"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.8 }}
      >
        <div className="container">
          <h1 className="display-4">About Seven Clothing</h1>
          <p className="lead">
            Discover the story behind your favorite t-shirts.
          </p>
        </div>
      </motion.section>

      {/* Our Story Section */}
      <motion.section
        className="our-story py-5"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <div className="container">
          <h2 className="text-center mb-4">Our Story</h2>
          <p>
            Seven Clothing was founded with a simple mission: to offer
            high-quality, stylish t-shirts that make you feel confident and
            comfortable. Our journey began with a vision to bring classic
            designs and trendy prints together, creating a collection that
            caters to every taste and occasion.
          </p>
          <p>
            Our commitment to excellence drives us to source the best fabrics
            and work with talented designers to ensure every piece we produce
            meets our high standards. At Seven Clothing, we believe that fashion
            should be both accessible and aspirational. Join us as we continue
            to redefine style and quality in the world of t-shirts.
          </p>
        </div>
      </motion.section>

      {/* Our Values Section */}
      <motion.section
        className="our-values bg-light py-5"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <div className="container">
          <h2 className="text-center mb-4">Our Values</h2>
          <div className="row">
            {[
              {
                title: "Quality",
                description:
                  "We prioritize quality in every step of our production process to ensure that each t-shirt meets the highest standards.",
              },
              {
                title: "Style",
                description:
                  "Our designs blend classic and contemporary styles to offer something for everyone.",
              },
              {
                title: "Customer Satisfaction",
                description:
                  "We value our customers and strive to provide exceptional service and support.",
              },
            ].map((value, index) => (
              <motion.div
                className="col-md-4 text-center mb-4"
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <h4>{value.title}</h4>
                <p>{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Call to Action Section */}
      <motion.section
        className="cta bg-secondary text-white text-center py-5"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <div className="container">
          <h2 className="mb-4">Join Our Journey</h2>
          <p>
            Stay connected with us to receive updates on new collections,
            exclusive offers, and more.
          </p>
          <a href="/contact" className="btn btn-light btn-lg">
            Contact Us
          </a>
        </div>
      </motion.section>
    </div>
  );
};

export default AboutPage;
